import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function AuthGuard({ children }) {
  const account = useSelector(state => state.account);

  if (!account.user) {
    return <Redirect to="/login" />;
  }
  if (account.user.roles) {
    if (
      account.user.roles.length > 0 &&
      account.user.roles[0].name === 'admin'
    ) {
      return <Redirect to="/administrator/dashboard" />;
    }
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
